import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withLocation from '@beelineloans/cx-library/src/utils/hoc/withLocation';
import Template from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import { HeaderWithButton } from '@beelineloans/cx-library/src/components/layout/headersv2';
import { H3, Paragraph } from '@beelineloans/cx-library/src/components/typography';
import { SocialProof } from '@beelineloans/cx-library/src/components/layout/panels';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import RatesCarousel from '@beelineloans/cx-library/src/components/RatesCarousel';
import { titleCase, getBool, applyEndpoint } from '@beelineloans/cx-library/src/utils/helpers';
import LoadingImage from '@beelineloans/cx-library/src/components/LoadingImage';
import LandingPageContent from '../../LandingPageContent';
import { ButtonOutline, Button } from '../../../../shared/GatsbyButton';

const NameQuote = styled(Paragraph)`
  text-align: center;
  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    padding-top: 90px;
  }
`;

const QuoteLoadingImage = styled(LoadingImage)`
  margin-top: 130px;
`;

const LoadingMessage = styled(H3)`
  padding-top: 30px;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  opacity: 0.5;
`;

const CustomizeButton = styled(ButtonOutline)`
  margin: 0 auto;
  width: 210px;
  margin-top: 35px;
  display: block;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

const ContinueButton = styled(Button)`
  margin: 0 auto;
  width: 210px;
  margin-top: 35px;
  display: block;

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    display: none;
  }
`;

const PersonalisedLandingPage = ({ search }) => {
  const ratesRef = useRef();
  const [programs, setPrograms] = useState(null);
  const [showProgram, setShowProgram] = useState(CONST.CALCULATOR.RATE_TYPES.LOWEST_MONTHLY);
  const [searchCriteria, setSearchCriteria] = useState(null);
  const [noResults, setNoResults] = useState(false);
  const isPurchase = (search.loantype || '').toLowerCase().indexOf('refi') === -1;
  const isLead = getBool(search.lead || '0');
  const headlineBlock = isPurchase ? (
    <>
      Get a home loan
      <br />
      from your sofa.
    </>
  ) : (
    <>Refi from your sofa.</>
  );
  const subheadlineBlock = (
    <>
      Skip the BS application.
      <br />
      In 15 minutes, get a low rate you can rely on without the origination fee.
      <br />
      <br />
      It’s a no brainer.
    </>
  );

  const onSearchComplete = (response, criteria) => {
    setSearchCriteria(criteria);
    if (response) {
      setPrograms(response.programs);
      setNoResults(response.status === 'NO_RESULTS' || response.status === 'NO_STATE');
    } else {
      setPrograms(null);
      setNoResults(true);
    }
  };

  const onCustomize = () => {
    ratesRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  const carouselChanged = (rateType) => {
    setShowProgram(rateType);
  };

  return (
    <Template
      headline={headlineBlock}
      subHeadline={subheadlineBlock}
      headerComponent={HeaderWithButton}
      headerChildrenRight={
        programs && searchCriteria && !noResults ? (
          <>
            <NameQuote>Here&apos;s your rate quote{search.name ? ` ${titleCase(search.name)}.` : '.'}</NameQuote>
            <RatesCarousel ratePrograms={programs} searchCriteria={searchCriteria} onChange={carouselChanged} />
            <CustomizeButton onClick={onCustomize}>View quote details</CustomizeButton>
            <ContinueButton to={applyEndpoint}>Continue</ContinueButton>
          </>
        ) : noResults ? (
          <>
            <RatesCarousel />
            <CustomizeButton onClick={onCustomize}>Try again</CustomizeButton>
          </>
        ) : (
          <>
            <QuoteLoadingImage />
            <LoadingMessage>Calculating your custom quote&hellip;</LoadingMessage>
          </>
        )
      }
      headerProps={{
        buttonText: 'Continue',
        options: {
          headerRatings: true
        }
      }}
    >
      <SEO
        path={CONST.LINKS.MAIN.LANDING.PERSONALISED}
        title="Get a home loan from your sofa in 15 mins"
        description="Skip the grueling application. Make offers like a boss with a Purchase-Ready Approval you can rely on."
        canonical={CONST.LINKS.MAIN.HOME}
      />
      <SocialProof />
      <LandingPageContent
        pageType={isPurchase ? 'purchase' : 'refinance'}
        onSearchComplete={onSearchComplete}
        ratesRef={ratesRef}
        isDynamicLP
        showDetails={showProgram}
        isLead={isLead}
      />
    </Template>
  );
};

PersonalisedLandingPage.propTypes = {
  search: PropTypes.object
};

PersonalisedLandingPage.defaultProps = {
  search: {}
};

export default withLocation(PersonalisedLandingPage);
